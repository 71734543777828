import { createGlobalStyle } from "styled-components"
import Colors from "./constants/colors"

export default createGlobalStyle`
  
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font: 15px 'Roboto mono', monospace;
  }

  h1{
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.5rem;
  }

  h2{
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2rem;
  }
  
  html, body, #root {
    height: 100%;
  }

  body {
    font: 15px 'Roboto mono', monospace;
    background: ${Colors.dark};
    color: ${Colors.white};
    -webkit-font-smoothing: antialiased !important;
  }

  div{
    display:flex;
    flex-direction:column;
  }

  ul {
    list-style: none;
  }
`
