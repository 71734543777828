import styled from "styled-components"
import { Layout } from "src/constants/layout"
import backgroundPattern from "src/assets/backgroundPatternDark.svg"

export const Wrapper = styled.div`
	min-height: 100%;
	background-image: linear-gradient(rgba(255,255,255,0.15), rgba(255,255,255,0.25)),url(${backgroundPattern});
	background-size: cover;
	width: 100%;
	flex: 1;
	padding: ${Layout.mdPadding};
`
