import { useEffect, useState } from "react"
import { Header } from "src/components/Header"
import useLinks from "src/hooks/useLinks"
import { BlogPost } from "src/interfaces/BlogPost"
import { Wrapper } from "./styles"
import { Avatar } from "src/components/Avatar"

export const Home = () => {
	const { handleProjectClick, handleAboutClick, handleContactClick } =
		useLinks()

	const [blogPosts, setBlogPosts] = useState<Array<BlogPost>>([])
	const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null)

	useEffect(() => {
		if (blogPosts.length > 0) {
			setSelectedPost(blogPosts[0])
		}
	}, [blogPosts])

	return (
		<Wrapper>
			<Header
				handleProjectClick={handleProjectClick}
				handleAboutClick={handleAboutClick}
				handleContactClick={handleContactClick}
			/>
			<div
				style={{
					flex: 1,
					textAlign: "center",
					alignSelf: "center",
					marginTop: 25,
				}}
			>
				<Avatar size={250} />
				<h2>Ricardo F. Thiesen </h2>
				<h3>Desenvolvedor Mobile</h3>
			</div>
		</Wrapper>
	)
}
