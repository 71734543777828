import styled from "styled-components"

import backgroundPattern from "src/assets/backgroundPatternDark.svg"
import { Layout } from "src/constants/layout"
import Colors from "src/constants/colors"

export const ContentWrapper = styled.div`
background-image: linear-gradient(rgba(255,255,255,0.6), rgba(255,255,255,0.3)),url(${backgroundPattern});
background-size: cover;
	width: 100%;
	flex: 1;
	padding: ${Layout.mdPadding};
	align-items: center;
`
