import React from "react"

interface AvatarProps {
	size?: number
	alt?: string
}

export const Avatar: React.FC<AvatarProps> = ({
	size = 50,
	alt = "Avatar",
}) => {
	const imageUrl = "https://github.com/FredThiesen.png"

	return (
		<img
			src={imageUrl}
			alt={alt}
			width={size}
			height={size}
			style={{
				borderRadius: "50%",
				alignSelf: "center",
				marginBottom: 10,
			}}
		/>
	)
}
