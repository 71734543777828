import { useEffect, useState } from "react"

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: typeof window === "object" ? window.innerWidth : 500,
		height: typeof window === "object" ? window.innerHeight : 500,
	})

	useEffect(() => {
		if (typeof window !== "object") return

		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			})
		}

		window.addEventListener("resize", handleResize)
		return () => window.removeEventListener("resize", handleResize)
	}, [])

	return windowSize
}
