import { memo, useMemo, useState } from "react"
import { Layout } from "src/constants/layout"
import { useWindowSize } from "src/hooks/useWindowSize"
import { MobileMenu } from "src/components/HeaderMobileMenu"
import { MenuLabel } from "src/components/MenuLabel"
import { MdMenu } from "react-icons/md"

import {
	HeaderContainer,
	LogoContainer,
	MenuContainer,
	MobileMenuContainer,
} from "./styles"

interface HeaderProps {
	handleProjectClick: () => void
	handleAboutClick: () => void
	handleContactClick: () => void
}

export const Header = memo((props: HeaderProps) => {
	const { handleProjectClick, handleAboutClick, handleContactClick } = props
	const [mobileMenuActive, setMobileMenuActive] = useState(false)
	const { width } = useWindowSize()
	const handleMenuDrawer = () => {
		setMobileMenuActive(!mobileMenuActive)
	}

	const isSmallScreen = useMemo(() => width < Layout.mdBreakpoint, [width])

	const renderMobileMenu = () => {
		return (
			<MobileMenuContainer
				onClick={handleMenuDrawer}
				isDrawerOpen={mobileMenuActive}
			>
				<MdMenu size={35} />
			</MobileMenuContainer>
		)
	}

	const renderDesktopMenu = () => {
		return (
			<MenuContainer>
				<MenuLabel title="github" onClick={handleProjectClick} />
				<MenuLabel title="sobre" onClick={handleAboutClick} />
				<MenuLabel title="contato" onClick={handleContactClick} />
			</MenuContainer>
		)
	}

	return (
		<HeaderContainer>
			<LogoContainer onClick={() => (window.location.href = "/")}>
				<h2>{"Ricardo Thiesen"}</h2>
			</LogoContainer>

			{!isSmallScreen ? renderDesktopMenu() : null}
		</HeaderContainer>
	)
})
